<template>
  <div>
    <UserComponent />
    <div class="mt-5">
      <!-- <AddressUser /> -->
    </div>

    <v-card>
      <v-toolbar flat color="#3C6681" dark>
        <v-toolbar-title class="d-flex justify-space-between" style="width: 100%; color: white">
          <div class="my-6">รายละเอียดการชำระเงิน</div>
          <div>
            <small class="red--text" style="font-size: 8px; color: white">วัน-เวลา ที่รับชำระ</small>
            <v-text-field
              :label="dateNow()"
              disabled
              placeholder="Placeholder"
              prepend-icon="mdi-calendar"
            ></v-text-field>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <!--
        expand add sale
        tag:
        :expanded.sync="expanded"
        show-expand
        item-expanded
       -->
      <div v-if="pathName == 'paySecondTime'">
        <v-data-table :items="itemsWithIndex" :headers="header" item-key="id" hide-default-footer>
          <template v-slot:[`item.unit_price`]="{ item }">
            <div>{{ formatInt(item.unit_price) }}</div>
          </template>
          <template v-slot:[`item.total_price`]="{ item }">
            <div>{{ formatInt(item.total_price) }}</div>
          </template>
          <template v-slot:[`item.remain`]="{ item }">
            <div>{{ formatInt(item.remain) }}</div>
          </template>
          <template v-slot:[`item.total_pay`]="{ item }">
            <div>{{ formatInt(item.total_pay) }}</div>
          </template>
          <template v-slot:[`item.price_pay`]="{ item }">
            <div v-if="item.order_type == 'mini_course'">
              <v-text-field
                type="number"
                v-model="order_list[item.index].price_pay"
                @change="handleChange(item.index)"
                color="purple darken-2"
                required
              ></v-text-field>
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <v-data-table ref="detailtable" item-key="index" :headers="headers" :items="itemsWithIndex" hide-default-footer>
          <!-- <template v-slot:body="props">
          <tbody>
            <tr v-for="item in props.items">
              <td class="d-block d-sm-table-cell" v-for="field in Object.keys(item)">
                {{ item[field] }}
              </td>
            </tr>
          </tbody>
        </template> -->
          <template v-slot:[`item.value`]>
            <div>1</div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div>
              {{ formatInt(item.price) }}
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ formatInt(item.price_total) }}
          </template>
          <template v-slot:[`item.remain`]="{ item }">
            <div>{{ formatFloat(item.remain) }}</div>
          </template>
          <template v-slot:[`item.down_price`]="{ item }">
            <div>
              {{ calculatePrice(item.price, item) }}
            </div>
          </template>
          <template v-slot:[`item.price_pay`]="{ item }">
            <div>
              <v-text-field
                type="number"
                v-model="order_list[item.index].price_pay"
                @change="handleChange(item.index)"
                color="purple darken-2"
                required
              ></v-text-field>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col class="my-auto">
                  <v-btn
                    color="primary"
                    @click="
                      handleAddSeller(
                        order_list
                          .map(function(x) {
                            return x.id
                          })
                          .indexOf(item.id),
                      )
                    "
                    rounded
                  >
                    <v-icon dark> mdi-plus </v-icon>เพิ่มผู้ขาย</v-btn
                  >
                </v-col>
                <v-col class="my-auto">
                  <v-radio-group row>
                    <v-radio v-for="(currency, n) in currencylist" :key="n" :label="currency.value"></v-radio>
                  </v-radio-group>
                </v-col>
                <div>
                  <v-row
                    class="mt-3"
                    v-for="(val, index) in array_seller[
                      order_list
                        .map(function(x) {
                          return x.id
                        })
                        .indexOf(item.id)
                    ]"
                    :key="index + 'sell'"
                  >
                    <v-col>
                      <v-select
                        v-model="
                          array_seller[
                            order_list
                              .map(function(x) {
                                return x.name
                              })
                              .indexOf(item.name)
                          ][index].user_id
                        "
                        dense
                        :items="items"
                        item-text="state"
                        item-value="abbr"
                        label="เลือกผู้ขาย"
                        outlined
                      ></v-select
                    ></v-col>
                    <v-col>
                      <v-row class="mb-3">
                        <v-col>
                          <v-text-field
                            v-model="
                              array_seller[
                                order_list
                                  .map(function(x) {
                                    return x.name
                                  })
                                  .indexOf(item.name)
                              ][index].circulation
                            "
                            dense
                            outlined
                            label="ยอดขาย"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="
                              array_seller[
                                order_list
                                  .map(function(x) {
                                    return x.name
                                  })
                                  .indexOf(item.name)
                              ][index].wayToSell
                            "
                            dense
                            :items="items"
                            item-text="state"
                            item-value="abbr"
                            label="ช่องทางปิดการขาย"
                            outlined
                          ></v-select
                        ></v-col>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row class="mb-3">
                        <v-col>
                          <v-btn
                            v-if="index != 0"
                            @click="
                              removeField(
                                array_seller[
                                  order_list
                                    .map(function(x) {
                                      return x.name
                                    })
                                    .indexOf(item.name)
                                ],
                                index,
                              )
                            "
                            fab
                            dark
                            small
                            color="error"
                            ><v-icon dark>mdi-close</v-icon></v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </td>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="error"
              @click="
                handleDialogCancel(
                  item,
                  order_list
                    .map(function(x) {
                      return x.id
                    })
                    .indexOf(item.id),
                )
              "
              icon
            >
              <v-icon dark> mdi-trash-can-outline </v-icon></v-btn
            >
          </template>
        </v-data-table>
      </div>

      <div>
        <v-row class="mx-auto pt-10">
          <v-col>
            <div>
              <b>ภาษีมูลค่าเพิ่ม</b>
              <div>
                <v-radio-group :disabled="pay_second != ''" v-model="vat_type" row>
                  <v-radio label="ไม่มี VAT" value="0"></v-radio>
                  <v-radio label="VAT (inc) 7%" value="1"></v-radio>
                  <v-radio label="VAT (exc) 7%" value="2"></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div>
              <v-card dark class="px-5 py-2 bg-main-rounded">
                <div v-if="pay_second == ''">
                  <v-row v-if="vat_type != 0">
                    <v-col>ราคาก่อนรวมภาษี</v-col>
                    <v-col class="text-right">{{ formatFloat(total_all_before_vat) }} THB</v-col>
                  </v-row>
                  <v-row v-if="vat_type != 0">
                    <v-col>ภาษีมูลค่าเพิ่ม</v-col>
                    <v-col v-if="pay_second == ''" class="text-right">(7%) {{ formatFloat(total_all_vat) }} THB</v-col>
                    <v-col v-else class="text-right">(7%) 0.00 THB</v-col>
                  </v-row>
                </div>
                <div v-if="pay_second == ''">
                  <v-row class="py-2">
                    <v-col>ยอดทั้งหมด</v-col>
                    <v-col v-if="vat_type == '2'" class="text-right"> {{ formatFloat(total_all_price) }} THB</v-col>
                    <v-col v-else class="text-right"> {{ formatFloat(total_all) }} THB</v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row class="py-7">
                    <v-col>คงค้างทั้งหมด</v-col>
                    <v-col v-if="vat_type != '2'" class="text-right"> {{ formatFloat(total_all_price) }} THB</v-col>
                    <v-col v-else class="text-right"> {{ formatFloat(total_all) }} THB</v-col>
                  </v-row>
                </div>
              </v-card>
            </div>
          </v-col>
          <v-col>
            <v-card dark class="px-5 py-1 bg-main" elevation="1">
              <h3 class="py-6">Totals</h3>
              <div v-if="pay_second == ''">
                <div class="py-4" v-if="vat_type == '0'">
                  <div class="d-flex justify-space-between">
                    <span class="mr-3 mt-4 headline">รวมทั้งหมด / บาท</span>
                    <span class="mt-4 headline">{{ formatFloat(total_pay_all) }} บาท</span>
                  </div>
                </div>
                <div v-if="vat_type == '1'">
                  <div class="d-flex justify-space-between">
                    <h4 class="mr-3 title">รวมทั้งหมด / บาท</h4>
                    <h4>{{ formatFloat(before_vat_price - total_all_vat) }} บาท</h4>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h4 class="mr-3 title">ภาษีมูลค่าเพิ่ม(7%) / บาท</h4>
                    <h4>{{ formatFloat(total_all_vat) }} บาท</h4>
                    <!-- total_all_vat -->
                  </div>

                  <div class="d-flex justify-space-between py-6">
                    <span class="mr-3 mt-4 headline">ชำระวันนี้ / บาท</span>

                    <span class="mt-4 headline">{{ formatFloat(total_pay_all) }} บาท</span>
                  </div>
                </div>
                <div v-if="vat_type == '2'">
                  <div class="d-flex justify-space-between">
                    <h4 class="mr-3 title">รวมทั้งหมด / บาท</h4>
                    <h4>{{ formatFloat(before_vat_price - total_all_vat) }} บาท</h4>
                  </div>
                  <div class="d-flex justify-space-between">
                    <h4 class="mr-3 title">ภาษีมูลค่าเพิ่ม(7%) / บาท</h4>
                    <h4 class="title">{{ formatFloat(total_all_vat) }} บาท</h4>
                  </div>

                  <div class="d-flex justify-space-between py-6">
                    <span class="mr-3 mt-4 headline">ชำระวันนี้ / บาท</span>

                    <span class="mt-4 headline">{{ formatFloat(total_pay_all) }} บาท</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="py-4">
                  <div class="py-4 d-flex justify-space-between">
                    <span class="mr-3 mt-4 headline">รวมทั้งหมด / บาท</span>
                    <span class="mt-4 headline">{{ formatFloat(total_pay_all) }} บาท</span>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="ma-3">
          <v-textarea
            outlined
            name="input-7-1"
            filled
            placeholder="หมายเหตุ"
            auto-grow
            value="หมายเหตุ"
            v-model="remark_pos"
          ></v-textarea>
        </div>
        <div class="mx-5">
          <v-checkbox v-model="showReceipt" label="แสดงในใบเสร็จ"></v-checkbox>
        </div>
        <div class="text-right ma-5 pa-4">
          <!-- <v-btn color="primary" class="mr-2"> <v-icon dark> mdi-cellphone </v-icon>โมบายแบงค์กิ้ง</v-btn> -->
          <v-btn class="mr-2" color="error" @click="handleBack"> ย้อนกลับ</v-btn>
          <v-btn color="success" @click="handleAddPayment"> <v-icon dark> mdi-cash </v-icon> ชำระเงิน</v-btn>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="dialogRemove" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> คุณต้องการลบรายการนี้ ? </v-card-title>
        <v-card-text class="text-center">
          <v-icon>mdi-trash</v-icon>
        </v-card-text>
        <v-card-actions class="mx-5">
          <v-btn color="error" @click="dialogRemove = false"> Disagree </v-btn>
          <v-btn color="success" @click="handleRemoveOrder()"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCheckBill" persistent max-width="750">
      <v-card>
        <v-card-title> เลือกช่องทางการชำระ </v-card-title>
        <v-card-text>
          <v-stepper v-model="page">
            <v-stepper-header>
              <v-stepper-step :complete="page > 1" step="1">เลือกช่องการชำระเงิน</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="page > 2" step="2">ยืนยันการชำระเงิน</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12" color="grey lighten-1">
                  <div>
                    <div class="error--text text-lg">ยอดชำระ {{ formatFloat(total_pay_all) }} บาท</div>
                    <div class="py-3">เลือกช่องการชำระเงิน</div>
                    <v-checkbox v-model="selected1" label="เงินสด"> </v-checkbox>
                    <v-text-field
                      type="number"
                      v-model="get_money1"
                      :disabled="selected1 != true"
                      placeholder="0"
                      label="จำนวนเงิน"
                      suffix="บาท"
                      outlined
                    ></v-text-field>

                    <v-checkbox v-model="selected2" label="โมบายแบงค์กิ้ง"> </v-checkbox>
                    <v-text-field
                      type="number"
                      v-model="get_money2"
                      :disabled="selected2 != true"
                      label="จำนวนเงิน"
                      placeholder="0"
                      suffix="บาท"
                      outlined
                    ></v-text-field>

                    <v-checkbox v-model="selected3" label="บัตรเครดิต"> </v-checkbox>
                    <v-text-field
                      type="number"
                      v-model="get_money3"
                      :disabled="selected3 != true"
                      label="จำนวนเงิน"
                      suffix="บาท"
                      outlined
                    ></v-text-field>
                    <v-checkbox v-model="selected4" label="อื่นๆ"> </v-checkbox>
                    <v-text-field
                      type="text"
                      v-model="get_text4"
                      v-show="selected4"
                      label="ช่องทาง"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      type="number"
                      v-model="get_money4"
                      v-show="selected4"
                      label="จำนวนเงิน"
                      suffix="บาท"
                      outlined
                    ></v-text-field>
                  </div>
                </v-card>
                <div v-show="checkButtonNext" class="text-right error--text" style="margin-top: -3%; margin-bottom: 3%">
                  ***จำนวนเงินไม่ถูกต้อง***
                </div>
                <div style="justify-content: center">
                  <v-row>
                    <v-col>
                      <v-btn color="secondary" block outlined @click="dialogCheckBill = false"> ยกเลิก </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn :disabled="checkButtonNext" color="primary" block @click="nextStep"> ถัดไป </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card class="mb-12" color="grey lighten-1">
                  <div>
                    <div><v-icon>mdi-calendar</v-icon> {{ dateNow() }}</div>
                    <div class="d-flex justify-space-between pt-6">
                      <h3>ยอดที่ชำระ</h3>
                      <div class="error--text text-lg">{{ formatFloat(total_pay_all) }} บาท</div>
                    </div>
                    <div class="d-flex justify-space-between pt-3">
                      <h3>จำนวนเงินที่รับมา</h3>
                      <div class="text-lg">{{ formatFloat(total_payment) }} บาท</div>
                    </div>
                    <div class="d-flex justify-space-between pt-3">
                      <h3 class="my-auto">เงินทอน</h3>
                      <div>
                        <h1 style="color: black !important">
                          {{ formatFloat(change_money) + ` บาท` }}
                        </h1>
                        <!-- <v-text-field
                            class="text-xl"
                            type="number"
                            :value="change_money"
                            disabled
                            suffix="บาท"
                            width="50"
                          ></v-text-field> -->
                      </div>
                    </div>
                  </div>
                </v-card>
                <div style="justify-content: center">
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col><v-btn color="secondary" block outlined @click="page = 1"> ย้อนกลับ </v-btn></v-col>
                    <v-col>
                      <v-btn color="primary" block :loading="loadingBtn" @click="handlePay"> ยืนยัน </v-btn></v-col
                    >
                  </v-row>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-container>
            <!-- <div class="d-flex justify-space-between">
                <div class="py-5">
                  <h2>{{ formatInt(price_total) }} บาท</h2>
                </div>
                <div>
                  <v-text-field @input="handleGetMoney" v-model="get_money" label="รับเงิน"></v-text-field>
                  <h2>เงินทอน {{ formatInt(change_money) }} บาท</h2>
                </div>
              </div> -->
          </v-container>
        </v-card-text>
        <v-card-actions style="justify-content: center; width: 50%; margin-left: 25%"> </v-card-actions>
      </v-card>
    </v-dialog>
    {{ checkTypeVat }}
    {{ checkSelectedPayment }}
  </div>
</template>
<script>
import UserComponent from '@/components/user/UserComponent.vue'
import AddressUser from '@/components/AddressUser/AddressUser.vue'
import FormatNumber from '@/helper/function'
import moment from 'moment'
export default {
  data() {
    return {
      type_currency: '',
      currencylist: [
        {
          id: 1,
          value: '%',
        },
        {
          id: 2,
          value: 'บาท',
        },
      ],
      expanded: [],
      singleExpand: false,
      header: [
        {
          text: 'รายการ',
          sortable: false,
          value: 'course_name',
        },
        // { text: 'ประเภท', value: 'order_type' },
        { text: 'จำนวน', value: 'value' },
        { text: 'พนักงานขาย', value: 'salesName' },
        { text: 'ผู้ให้บริการ', value: 'providerName' },
        { text: 'ราคา / ชิ้น', value: 'price' },
        { text: 'ยอดรวม', value: 'id' },
        { text: 'ชำระแล้ว', value: 'total_pay' },
        { text: 'ค้างชำระ', value: 'remain' },
        { text: 'ชำระ', value: 'price_pay' },
        { text: '', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      /// จ่ายครั้งแรก
      headers: [
        // {
        //   text: '',
        //   align: 'start',
        //   width: '10%',
        //   value: 'total_many',
        // },
        {
          text: 'รายการ',
          sortable: false,
          value: 'course_name',
        },
        // { text: 'ประเภท', value: 'order_type' },
        { text: 'จำนวน', value: 'value' },
        { text: 'พนักงานขาย', value: 'salesName' },
        { text: 'ผู้ให้บริการ', value: 'providerName' },
        { text: 'ราคา / ชิ้น', value: 'price' },
        { text: 'ยอดรวม', value: 'id' },
        { text: 'ชำระแล้ว', value: 'pay_at' },
        { text: 'ค้างชำระ', value: 'remain' },
        { text: 'ชำระ', value: 'price_pay' },
        { text: '', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      array_seller: [],
      vat_type: '0',
      remark_pos: '',
      showReceipt: false,
      beforeBuy: false,
      price: this.$store.getters.dataOrders.total_pay,
      price_total: '',
      before_vat_price: '',
      vat_price: 0,
      dialogRemove: false,
      data_remove: '',
      index_remove: '',
      order_list: [],
      dialogCheckBill: false,
      loadingBtn: false,
      get_money: 0.0,
      change_money: '',
      total_all: this.$store.getters.dataOrders.total_price,
      selected: [],
      money: [],
      selected1: true,
      selected2: false,
      selected3: false,
      selected4: false,
      get_money1: '',
      get_money2: '',
      get_money3: '',
      get_money4: '',
      get_text4: '',
      page: 1,
      data_get: [],
      btn_next: true,
      total_payment: '',
      id_to_bill: '',
      text_error: false,
      pathName: this.$route.name,
      data_send: {},
      pay_second: '',
      total_pay_all: this.$store.getters.dataOrders.total_pay,
      total_all_price: '',
      total_all_vat: '',
      total_all_before_vat: '',
      reloadPage: false,
      detail_order: '',
    }
  },
  setup() {},
  mounted() {
    console.log(this.$store.getters.dataOrders)
    // this.openAll()
    // if (this.$store.getters.getMiniCourse == '') {
    //   this.$router.push('/openbill')
    // }
    // this.setSeller()
    this.getListOrder()

    this.pay_second = this.$route.query.action == 'payorders' ? 'payorders' : ''
  },
  components: {
    UserComponent,
    AddressUser,
  },
  computed: {
    itemsWithIndex() {
      return this.order_list.map((items, index) => ({
        ...items,
        index: index,
      }))
    },
    checkTypeVat() {
      var total_to_pay = 0
      this.order_list.map(val => {
        console.log(val)
        if (val.order_type == 'product') {
          total_to_pay += parseFloat(val.price_total)
        } else {
          total_to_pay += parseFloat(val.price_pay)
        }
      })
      console.log(total_to_pay)
      if (this.pay_second == '') {
        if (this.vat_type == '1') {
          // total
          this.total_all_before_vat = this.total_all * (100 / 107)
          this.total_all_vat = this.total_all - this.total_all_before_vat
          this.total_all_price = this.total_all_before_vat + this.total_all_vat
          // pay
          this.before_vat_price = total_to_pay
          this.vat_price = this.before_vat_price * (100 / 107)
          this.total_pay_all = this.before_vat_price
        } else if (this.vat_type == '2') {
          // total
          this.total_all_before_vat = this.total_all
          this.total_all_vat = this.total_all * (7 / 100)
          this.total_all_price = this.total_all_before_vat + this.total_all_vat
          // pay
          this.before_vat_price = total_to_pay
          // this.vat_price = this.total_all * (7 / 100)
          this.total_pay_all = this.total_all_vat + this.before_vat_price
        } else {
          this.total_pay_all = total_to_pay
          this.total_all_vat = 0
          this.total_all_price = this.total_all
          this.vat_price = 0
        }
      }
    },
    checkSelectedPayment() {
      // if (this.selected1 && !this.selected2 && !this.selected3 && !this.selected4) {
      //   this.total_payment = parseFloat(this.get_money1)
      // } else if (this.selected2 && !this.selected1 && !this.selected3 && !this.selected4) {
      //   this.total_payment = parseFloat(this.get_money2)
      // } else if (this.selected3 && !this.selected1 && !this.selected2 && !this.selected4) {
      //   this.total_payment = parseFloat(this.get_money3)
      // } else if (this.selected4 && !this.selected1 && !this.selected2 && !this.selected3) {
      //   this.total_payment = parseFloat(this.get_money4)
      // } else {
      //   this.total_payment = this.total_all_pay
      // }
      // this.change_money = parseFloat(this.total_payment) - parseFloat(this.total_pay_all)
      this.handleSelectedPayment()
    },

    checkButtonNext() {
      var payCash = this.selected1 ? parseFloat(this.get_money1) : false
      var payBanking = this.selected2 ? parseFloat(this.get_money2) : false
      var payCredit = this.selected3 ? parseFloat(this.get_money3) : false
      var payEtc = this.selected4 ? parseFloat(this.get_money4) : false
      var total_user_pay = payCash + payBanking + payCredit + payEtc
      this.total_all_pay = total_user_pay
      console.log(total_user_pay)
      if (this.get_money1 || this.get_money2 || this.get_money3 || this.get_money4) {
        if (this.get_text4 == '' && this.selected4) {
          return true
        }
        if (total_user_pay >= this.total_pay_all) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },

  methods: {
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex(i => i === item)
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item)
      }
    },
    openAll() {
      for (let i = 0; i < this.order_list.length; i += 1) {
        this.expanded.push(this.order_list[i])
      }
    },
    addOrder(res) {
      console.log(res)
      res.value += 1
    },
    minusOrder(res) {
      res.value -= 1
    },
    handleAddSeller(key) {
      console.log(key)
      console.log(this.array_seller[key])
      this.array_seller[key].push({
        user_id: '',
        circulation: 0,
        wayToSell: '',
      })
    },
    setSeller() {
      let setArray = []
      for (let j = 0; j < this.order_list.length; j++) {
        setArray.push([
          {
            user_id: '',
            circulation: 0,
            wayToSell: '',
          },
        ])
      }

      this.array_seller = setArray
    },
    removeField(index, field) {
      index.splice(field, 1)
    },
    handleDialogCancel(data, idx) {
      console.log(data)
      console.log(idx)
      this.index_remove = idx
      this.data_remove = data
      this.dialogRemove = true
    },
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    async handleRemoveOrder() {
      this.dialogRemove = true
      var price_check = []
      var price_total_pay = 0
      var price_total_all = 0
      this.order_list.splice(this.index_remove, 1)
      this.order_list.forEach(function(res) {
        price_check = {
          total_pay: (price_total_pay += res.price_pay),
          total_price: (price_total_all += res.price_total),
        }
      })
      Object.assign(price_check, { orders: this.order_list })

      this.$store.commit('SET_ORDER', price_check)
      this.$nextTick(() => {
        console.log(this.$store.getters.dataOrders)
        this.order_list = this.$store.getters.dataOrders.orders
        this.total_pay_all = this.$store.getters.dataOrders.total_pay
        this.total_all = this.$store.getters.dataOrders.total_price

        const product_order = this.$store.getters.getProduct
        const course_order = this.$store.getters.getCourse
        if (this.data_remove.order_type == 'product') {
          product_order.map((val, key) => {
            if (this.data_remove.id == val.id) {
              console.log(val)
              console.log(key)
              product_order.splice(key, 1)
            }
          })
          console.log(product_order)
          this.$store.commit('SET_PRODUCT_ORDER', product_order)
        }
        if (this.data_remove.order_type == 'course') {
          course_order.map((val, key) => {
            if (this.data_remove.id == val.id) {
              console.log(val)
              console.log(key)
              course_order.splice(key, 1)
            }
          })
          console.log(product_order)
          this.$store.commit('SET_COURSE_ORDER', course_order)
        }
        // data_remove

        // this.reloadPage = true
        this.$router.go()
        this.dialogRemove = false
        // this.$forceUpdate();
      })
    },
    calculatePrice(price, type) {
      const amount = type.order_type == 'course' ? 1 : type.value
      const total = price * amount
      return FormatNumber.formatInt(total)
    },
    calculateTotal(data, key) {
      if (data.down_payment == 1) {
        return data.down_price
      } else {
        return data.price
      }
    },
    handleChange(res) {
      console.log(res)
      console.log(this.pathName)
      if (this.pay_second == '') {
        if (this.order_list[res].price_pay >= this.total_all) {
          this.order_list[res].price_pay = this.total_all
        } else if (this.order_list[res].price_pay <= this.order_list[res].total_all) {
          this.order_list[res].price_pay = this.order_list[res].price_pay
        }
      } else {
        if (this.order_list[res].price_pay >= this.order_list[res].remain) {
          this.order_list[res].price_pay = this.order_list[res].remain
        } else if (this.order_list[res].price_pay <= this.order_list[res].remain) {
          this.order_list[res].price_pay = this.order_list[res].price_pay
        }
      }
      var total = 0
      console.log(this.order_list[res].price_pay)

      this.order_list.map(val => {
        const many_price = val.price_pay
        console.log(val.price_pay)
        var price = parseInt(many_price)
        total += price
      })
      this.price = total
      if (this.vat_type == 1) {
        this.total_pay_all = total
      } else {
        this.total_pay_all = total + this.total_all_vat
      }
    },
    nextStep() {
      this.handleSelectedPayment()
      this.page = 2
    },
    handleGetMoney() {
      this.change_money = parseInt(this.get_money) - parseInt(this.total_pay_all)
    },
    handleAddPayment() {
      this.dialogCheckBill = true
    },
    dateNow() {
      return moment().format('DD-MM-YYYY')
    },
    handleAddOrder(item) {
      const getArray = []
      const data_order = []
      this.order_list.map(val => {
        console.log(val)
        if (this.pathName == '') {
          data_order.push({
            id: val.id,
            name_th: val.course_name,
            amount: 1,
            unit_price: val.price,
            total_price: val.price,
            total_pay: parseInt(val.price_pay),
            remain: val.price_total - parseInt(val.price_pay),
            order_type: val.order_type,
          })
        } else {
          data_order.push({
            product_id: val.product_id,
            order_name: val.course_name,
            amount: val.amount,
            unit_price: val.unit_price,
            total_pay: val.price_pay,
            order_type: val.order_type,
          })
        }
      })

      const payment = {
        id: this.id_to_bill,
        note: this.showReceipt ? this.remark_pos : '',
        total_pay: parseFloat(this.total_pay_all),
        all_product: this.pay_second == '' ? this.data_send.all_product : data_order,
        payment: [],
      }
      if (this.get_money1) {
        getArray.push({
          total_pay: parseFloat(this.get_money1) - parseFloat(this.change_money),
          payment_method: 1,
          get_money: parseFloat(this.get_money1),
          change_money: parseFloat(this.change_money).toFixed(2),
        })
      }
      if (this.get_money2) {
        getArray.push({
          total_pay: parseFloat(this.get_money2),
          payment_method: 2,
          get_money: parseInt(this.get_money2),
          change_money: 0,
        })
      }
      if (this.get_money3) {
        getArray.push({
          total_pay: parseFloat(this.get_money3),
          payment_method: 3,
          get_money: parseInt(this.get_money3),
          change_money: 0,
        })
      }
      if (this.get_money4) {
        getArray.push({
          total_pay: parseFloat(this.get_money4),
          method_text: this.get_text4,
          payment_method: 4,
          get_money: parseInt(this.get_money4),
          change_money: 0,
        })
      }
      // Object.assign(payment.payment, getArray)
      payment.payment = getArray
      console.log(payment)
      this.$store.dispatch('payOrder', payment).then(res => {
        // console.log(res)
        // this.dialogCheckBill = false
        // this.$root.$emit('resetOrder')
        // this.$router.push(`/list-order-daily/billing/${res.id}`)
        if (item && item.length && res.receipt_code) {
          item.receipt_code = res.receipt_code
          // item.forEach(function(data) {
          //   data.receipt_code = res.receipt_code
          // })
          this.$store.dispatch('postCalCommission', item).then(response => {
            console.log(response)
            this.handleRedirectBilling(res.id)
          })
        } else {
          this.handleRedirectBilling(res.id)
        }
      })
    },
    handleRedirectBilling(id) {
      this.dialogCheckBill = false
      this.loadingBtn = false
      this.$root.$emit('resetOrder')
      this.$router.push(`/list-order-daily/billing/${id}`)
    },
    handlePay() {
      this.loadingBtn = true
      console.log(this.order_list)
      const total_all = this.total_all
      const before_price = this.vat_type != '0' ? this.before_vat_price : this.total_pay_all
      const remain = total_all - this.total_pay_all
      const set_data = []
      this.order_list.map(val => {
        console.log(val)
        set_data.push({
          id: val.id,
          name_th: val.course_name,
          amount: 1,
          unit_price: val.price,
          total_price: val.price,
          total_pay: parseInt(val.price_pay),
          remain: val.remain - val.price_pay,
          order_type: val.order_type,
        })
      })
      const data_send = {
        user_id: localStorage.getItem('user_id'),
        vat_type: this.vat_type,
        vat_price: this.total_all_vat,
        before_vat_price: before_price,
        include_vat_price: this.total_pay_all,
        total_all: this.vat_type == 2 ? this.total_all_price : total_all, //ราคารวม
        all_product: set_data,
      }
      console.log(data_send)

      if (this.pathName == 'minicourse') {
        this.$store.dispatch('createOrder', data_send).then(res => {
          let ordernew = [...this.order_list]
          let payload = res.order_list.map(item => {
            let ordernewfilter = ordernew.find(f => f.id === item.original_product_id)
            ordernew.splice(
              ordernew.findIndex(e => e.id === item.original_product_id),
              1,
            )
            if (!ordernewfilter.providerSales) {
              ordernewfilter.providerSales = {}
            }
            return {
              provider_id: ordernewfilter.providerSales?.doctor_id,
              sales_id: ordernewfilter.providerSales?.sales_id,
              type_course_id: 2,
              user_course_mini_id: ordernewfilter.id,
              pay: item.total_pay,
              course_description: item.order_name,
            }
          })
          this.id_to_bill = res.id
          // this.$store.dispatch('postCalCommission', payload)

          this.data_send = data_send
          // this.dialogCheckBill = true
          this.handleAddOrder(payload)
        })
      } else {
        this.id_to_bill = this.$route.params.order_id
        // this.dialogCheckBill = true
        this.handleAddOrder()
      }
    },
    handleBack() {
      this.$router.push('/openbill')
    },
    getListOrder() {
      let price_to_pay = 0
      this.order_list = this.$store.getters.dataOrders.orders
      if (this.$route.query.action == 'payorders') {
        const res = this.$store.getters.dataOrders.data
        console.log(res)
        this.detail_order = res
        this.vat_type = res.vat_type.toString()
        this.before_vat_price = res.before_vat_price
        this.total_all_vat = res.vat_price
        this.order_list.forEach(function(data) {
          console.log(data)
          price_to_pay += parseInt(data.price_pay)
          // price_to_pay
        })
      } else {
        this.order_list.forEach(function(data) {
          console.log(data)
          price_to_pay += data.price_pay
          // price_to_pay
        })
      }
      this.total_pay_all = price_to_pay
    },
    handleSelectedPayment() {
      if (this.selected1 && !this.selected2 && !this.selected3 && !this.selected4) {
        this.total_payment = parseFloat(this.get_money1)
      } else if (this.selected2 && !this.selected1 && !this.selected3 && !this.selected4) {
        this.total_payment = parseFloat(this.get_money2)
      } else if (this.selected3 && !this.selected1 && !this.selected2 && !this.selected4) {
        this.total_payment = parseFloat(this.get_money3)
      } else if (this.selected4 && !this.selected1 && !this.selected2 && !this.selected3) {
        this.total_payment = parseFloat(this.get_money4)
      } else {
        this.total_payment = parseFloat(this.total_all_pay)
      }
      this.change_money = parseFloat(this.total_payment) - parseFloat(this.total_pay_all)
      this.handleSetZero()
    },
    handleSetZero() {
      if (!this.selected1) {
        this.get_money1 = 0
      }
      if (!this.selected2) {
        this.get_money2 = 0
      }
      if (!this.selected3) {
        this.get_money3 = 0
      }
      if (!this.selected4) {
        this.get_money4 = 0
      }
    },
  },
}
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table {
  width: 99% !important;
  border-spacing: 0 !important;
}
.bg-main-rounded {
  background-color: #1a445f !important;
  border: 5px solid #3c6681 !important;
}
.bg-main {
  background-color: #3c6681 !important;
}
.theme--dark.v-sheet h1,
.theme--dark.v-sheet h2,
.theme--dark.v-sheet h3,
.theme--dark.v-sheet h4,
.theme--dark.v-sheet h5,
.theme--dark.v-sheet h6 {
  color: white !important;
}
.theme--dark.v-sheet {
  color: white !important;
}
.headline,
.title {
  font-family: 'Noto Sans Thai', sans-serif !important;
}
</style>
